import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Section } from '../components/Section';
import { FeaturesSection } from '../pageSections/FeaturesSection';

import { EnterpriseSection } from '../pageSections/EnterpriseSection';
import { GenericHeroSection } from '../pageSections/GenericHeroSection';
import { ProductsSection } from '../pageSections/ProductsSection';
import { GenericBenefitsSection } from '../pageSections/GenericBenefitsSection';
import { CountriesAllProductsSection } from '../pageSections/CountriesAllProductsSection';
import { ClientsLogosSection } from '../pageSections/ClientsLogosSection';
import { Layout } from '../layout/Layout';

const sectionMapping = {
  Strapi_ComponentPageSectionsHeroSection: props => (
    <GenericHeroSection {...props} layout="imageLeftTextRight" />
  ),
  Strapi_ComponentPageSectionsBenefitsSection: GenericBenefitsSection,
  Strapi_ComponentPageSectionsFeaturesSection: FeaturesSection,
  Strapi_ComponentPageSectionsEnterpriseSection: EnterpriseSection,
  Strapi_ComponentPageSectionsProductsSection: ProductsSection,
  Strapi_ComponentPageSectionsClientsLogos: ClientsLogosSection,
};

export default ({ data }) => {
  const { SeoInfo, sections } = data.strapi.homePage;
  return (
    <Layout>
      <SEO
        title={SeoInfo.pageTitle}
        description={SeoInfo.pageDescription}
        keywords={SeoInfo.keywords}
      />
      {sections.map(s => {
        return (
          <Section
            backgroundVariant="increaseHome"
            mapping={sectionMapping}
            key={`${s.type}${s.id}`}
            {...s}
          />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  fragment CTA on Strapi_ComponentGenericCta {
    text
    href
  }

  fragment BigImage on Strapi_UploadFile {
    url
    imageSharp {
      childImageSharp {
        fluid(maxWidth: 900, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }

  query {
    strapi {
      homePage {
        SeoInfo {
          pageDescription
          pageTitle
          keywords
        }
        sections {
          type: __typename
          ... on Strapi_ComponentPageSectionsHeroSection {
            id
            title
            subtitle
            description
            productName
            image {
              ...BigImage
            }
            primaryCTA {
              ...CTA
            }
            secondaryCTA {
              ...CTA
            }
          }
          ... on Strapi_ComponentPageSectionsFeaturesSection {
            id
            features {
              id
              title
              name
              description
              image {
                ...Image
              }
              ctaText
              ctaUrl
            }
          }

          ... on Strapi_ComponentPageSectionsBenefitsSection {
            id
            benefits {
              id
              name
              description
              image {
                url
                imageSharp {
                  publicURL
                }
              }
            }
          }

          ... on Strapi_ComponentPageSectionsClientsLogos {
            id
            title
            subtitle
            rows {
              clients {
                name
                logo {
                  ...Image
                }
              }
            }
            cta {
              ...CTA
            }
          }

          ... on Strapi_ComponentPageSectionsProductsSection {
            id
            title
            description
            primaryCTA {
              text
              href
            }
          }

          ... on Strapi_ComponentPageSectionsEnterpriseSection {
            id
            description
            cta {
              ...CTA
            }
          }
        }
      }
    }
  }
`;
